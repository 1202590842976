import { DateTime as LuxonDateTime, Duration as LuxonDuration } from 'luxon'
import Vue from 'vue'
import AirisNotifier from '../../airis-notifier'
const SunCalc = require('suncalc')
const axios = require('axios')

let apiHistoryRoot = '/proxy/solar_energy'
let apiPowerFlowRoot = '/proxy/solar_status'

var throttlePercent = 0.33

var solarIDForLocation = (location) => {
  return location.id
}

var latForLocation = (location) => {
  return location.lat
}

var lonForLocation = (location) => {
  return location.lon
}

var getSunTimesForLocation = (luxonDateTime, location) => {
  return SunCalc.getTimes(luxonDateTime.toJSDate(), latForLocation(location), lonForLocation(location))
}

var getAltTemp = ( unit, temp ) => {
  if( unit === 'f' ) {
    return Math.round( ( 5.0 / 9.0 ) * ( temp - 32.0 ) );
  } else {
    return Math.round( ( 9.0 / 5.0 ) * temp + 32.0 );
  }
}

var solarErrorNotification = ( solarID, axiosError, commit ) => {
  var error
  var errorData = ""
  var errorType = ""

  if (axiosError.isAxiosError && axiosError.response !== undefined) {
    error = axiosError.response
    errorData = error.data
    errorType = error.status
  }else{
    error = axiosError
  }

  var solarError = { }
  AirisNotifier.error( "Solar Retrieval Error", axiosError);
  if(error === undefined){
    AirisNotifier.error("Unknown Solar Retrieval Error", axiosError)
    solarError.active = true
    solarError.message = "Solar updates on hold"
  }else if(errorType == 500){
    AirisNotifier.error("AIRIS Solar Retrieval Error", axiosError)
    solarError.active = true
    solarError.message = "Solar update failed"
  }else if(errorType == 429 || errorData.startsWith('daily usage limit')) {
    solarError.active = true
    solarError.message = "Solar updates are paused"
  }else {
    AirisNotifier.error("Solar Retrieval Error", axiosError)
    solarError.active = true
    solarError.message = "Solar on pause"
  }
  commit('solarState', { solarID: solarID, opts: {
    location: location, refresh: false, error: true, errorMessage: solarError.message, lastRefresh: LuxonDateTime.local() }})
  return solarError
  }

// shape: [{ id, quantity }]
const state = {
  storeState: {

  },
  solar: {

  },
  getSolarID: solarIDForLocation
}

// getters
const getters = {
  solar: (state) => (location) => {
    let solarID = solarIDForLocation(location)
    let solar = state.storeState[solarID]

    return solar
  },

  shouldSolarBeActive: (state, getters, rootState) => (location) => {
    if(rootState.browser.hidden){
      if(rootState.app.debug){
        console.log("Browser not active, solar inactive", rootState.browser)
      }
      return false
    }

    var timeNow = LuxonDateTime.local()
    let solarID = solarIDForLocation(location)
    if(state.solar[solarID] === undefined || state.solar[solarID] === null) {
      return true
    }
    
    var sunTimes = getSunTimesForLocation(timeNow, location)
    var maxTime = LuxonDateTime.fromJSDate(sunTimes.sunrise) // timeNow.set({hour: 6})
    var minTime = LuxonDateTime.fromJSDate(sunTimes.sunset) // timeNow.set({hour: 23 })
    var maxTime = timeNow.set({hour: 6})
    var minTime = timeNow.set({hour: 23 })
    
    if(timeNow < maxTime || timeNow > minTime){
      state.solar[solarID].throttleCount += 1

      if (state.solar[solarID].throttleCount >= Math.floor(1.0 / throttlePercent)){
        state.solar[solarID].throttleCount = 0
        // console.log("Allowing thru throttle", Math.floor(1.0 / this.throttlePercent))
        return true
      }
      // console.log("Throttling",state.solar[solarID].throttleCount,  Math.floor(1.0 / this.throttlePercent))
      return false
    }else {
      // state.solar[solarID].throttleCount = 0
    }
    return true
  }
}



// actions
const actions = {
  watchSolar({ dispatch, commit }, location) {
    let solarID = solarIDForLocation(location)

    if(state.solar[solarID] === undefined || state.solar[solarID] === null) {
      commit('setup', {solarID: solarID, location: location})
      commit('solarState', { solarID: solarID, opts: {
        location: location, refreshing: true, error: false, lastRefresh: LuxonDateTime.local() }})
      return dispatch('updateSolarHistory', location).then(() => {
        dispatch('periodicSolarUpdate', location)
      })
    }

    return new Promise((resolve, reject) => { })
  },
  periodicSolarUpdate({ dispatch, commit }, location) {
    return new Promise((resolve, reject) => {
      setInterval(() => {
        dispatch('updateSolarHistory', location)
      }, 15 * 1000 * 60)
      resolve()
    })
  },
  updateSolarHistory({ getters, commit, rootState }, location) {
    let solarID = solarIDForLocation(location)
    return new Promise((resolve, reject) => {
      if(getters.shouldSolarBeActive(solarID, null)) {

        commit('solarState', { solarID: solarID, opts: {
          location: location, refresh: true, error: false, errorMessage:"", lastRefresh: LuxonDateTime.local() }})

        var timeNow = LuxonDateTime.local()
        var sunTimes = getSunTimesForLocation(timeNow, location)
        var sunrise =  LuxonDateTime.fromJSDate(sunTimes.sunrise)
        var sunset =  LuxonDateTime.fromJSDate(sunTimes.sunset)
        
        var maxTime = timeNow.minus({minutes:1})

        var minTime = timeNow.startOf('day')
       
        // If it's before sunrise, display the previous day's solar output 
        if(timeNow < sunrise){
          sunTimes = getSunTimesForLocation(timeNow.minus({days:1}), location)
        }
        
        axios.get(apiHistoryRoot,{
          params: {
            site_id: solarID,
            time_unit: "QUARTER_OF_AN_HOUR",
            start_time:minTime.toFormat('y-LL-dd T'),
            end_time:maxTime.toFormat('y-LL-dd T'),
          },
        })
        .then(function(result) {
          var solarData = {}
          solarData = result.data
          if(rootState.app.debug){
            console.log("Solar Energy Result", solarID, solarData)
          }
          let solar = {solarID: solarID, solarHistory: solarData, sunrise: sunrise, sunset: sunset };
          commit('mutateSolarInfo', solar)
          commit('solarState', { solarID: solarID, opts: {
            location: location, refresh: false, error: false, lastRefresh: LuxonDateTime.local()} })
          // resolve()
        })
        .catch(function(error) {
          solarErrorNotification(solarID, error, commit);
          // resolve()
        })
        .then(function () {
          resolve()
        })

        axios.get(apiPowerFlowRoot,{
          params: {
            site_id: solarID,
          },
        })
        .then(function(result) {
          // console.log("Solar Power Flow Result", solarID, result)
          var solarData = {}
          solarData = result.data
          let solar = {solarID: solarID, powerFlow: solarData};
          commit('mutateSolarInfo', solar)
          commit('solarState', { solarID: solarID, opts: {
            location: location, refresh: false, error: false, lastRefresh: LuxonDateTime.local()} })
        })
        .catch(function(error) {
          solarErrorNotification(solarID, error, commit);
        })
        .then(function () {
          resolve()
        })

      }else{
        resolve()
      }
    })
  }
}

// mutations
const mutations = {
  setup(state, solar){
    let solarID = solar.solarID
    let opts = { id: solarID,
                 location: solar.location,
                 refresh: false,
                 error: false,
                 errorMessage: "",
                 lastRefresh: false,
                 throttleCount: Infinity,
               }
    if(state.solar[solarID] === undefined || state.solar[solarID] === null){
      state.solar[solarID] = opts
    }
  },
  mutateSolarInfo (state, solarData) {
    let solarID = solarData.solarID
    let solarHistory = solarData.solarHistory
    let powerFlow = solarData.powerFlow

    var today = LuxonDateTime.local()

    let opts = {solarID: solarID, solarHistory: solarHistory, powerFlow: powerFlow }
    if(state.solar[solarID] === undefined || state.solar[solarID] === null){
      state.solar[solarID] = opts
    }else {
      if(solarHistory !== undefined || solarHistory !== null){
        state.solar[solarID].solarHistory = solarHistory
      }
      if(powerFlow !== undefined || powerFlow !== null){
        state.solar[solarID].powerFlow = powerFlow
      }
    }
  },
  solarState (state, solar) {
    let solarID = solar.solarID
    let opts = solar.opts
    if(state.storeState[solarID] === undefined || state.storeState[solarID] === null){
      // debugger
      state.storeState[solarID] = { refresh: false, error: false, errorMessage: "", lastRefresh: LuxonDateTime.local() }
    }

    if(opts.refresh !== undefined && opts.refresh !== null){
      state.storeState[solarID].refresh = opts.refresh
    }
    if(opts.error !== undefined && opts.error !== null){
      state.storeState[solarID].error = opts.error
    }
    if(opts.errorMessage !== undefined && opts.errorMessage !== null){
      state.storeState[solarID].errorMessage = opts.errorMessage
    }
    if(opts.location !== undefined && opts.location !== null){
      state.storeState[solarID].location = opts.location
    }
    if(opts.lastRefresh !== undefined && opts.lastRefresh !== null){
      state.storeState[solarID].lastRefresh = opts.lastRefresh
    }
    if(opts.solarHistory !== undefined && opts.solarHistory !== null){
      state.storeState[solarID].solarHistory = opts.solarHistory
    }
    if(opts.powerFlow !== undefined && opts.powerFlow !== null){
      state.storeState[solarID].powerFlow = opts.powerFlow
    }

  }
}

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
