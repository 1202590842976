<template>
<div>
  <div class='tomorrow' v-if='displayChores' >
    <div class="title">{{choreTitle}}</div>
    <div class='refuseCollection mt-4' v-if='nextTrashCollection'>
      <div v-if='displaTrashCollection'>
        <div v-if='nextTrashCollection.garbage' class="pickup trash"> </div>
        <div v-if='nextTrashCollection.compost' class="pickup compost"> </div>
        <div v-if='nextTrashCollection.recycling' class="pickup recycling"> </div>
      </div>
      <div v-if='displayRidwell'>
        <div v-if='nextRidwellCollection.plastics' class="pickup ridwell"><font-awesome-icon :icon="['fal', 'sack']" /></div>
      </div>
     </div>
  </div>
  <div v-if='choresError' class='tomorrow' >
    <div class='refuseCollection'> 
      <div class='icon title error'>
        <font-awesome-icon :icon="['fal', 'engine-warning']" /> Trash Collection Schedule
      </div> 
    </div>
  </div>
</div>
</template>

<script>
import moment from "moment"
import AirisNotifier from '../src/airis-notifier'
import { DateTime as LuxonDateTime, Duration as LuxonDuration } from 'luxon'
const axios = require('axios')
import { useIntervalFn } from '@vueuse/core'

export default {
  name: 'chores',
  data: () => {
  return {
    choreTitle: 'Future',
    displayChores: false,
    choresError: false,
    nextTrashCollection: null,
    displayRidwell: false,
    displaTrashCollection: false,
    currentDayMoment: moment().startOf('day'),
  }
  },
  computed: {

  },
  methods: {
    fetchChores(){
      this.choresError = false
      
      this.fetchWasteCollection()
      this.fetchhRidwellCollection()
      this.refreshWasteCollection()
    },
    refreshWasteCollection() {
      var todayMoment = LuxonDateTime.local().startOf('day')
      var tmwMoment = LuxonDateTime.local().startOf('day').plus({days: 1})
      this.currentDayMoment = todayMoment
      var displayTmwCollection = {
        trash: false,
        ridwell: false,
      }
      var displayTodayCollection = {
        trash: false,
        ridwell: false,
      }
      if(this.nextTrashCollection !== null && this.nextTrashCollection.luxonStart !== undefined){
        displayTmwCollection.trash = tmwMoment.hasSame(this.nextTrashCollection.luxonStart, 'day')
        displayTmwCollection.ridwell = tmwMoment.hasSame(this.nextRidwellCollection.luxonStart, 'day');
        
        displayTodayCollection.trash = todayMoment.hasSame(this.nextTrashCollection.luxonStart, 'day')
        displayTodayCollection.ridwell = todayMoment.hasSame(this.nextRidwellCollection.luxonStart, 'day');
      }

      this.displayRidwell = displayTmwCollection.ridwell ||  displayTodayCollection.ridwell
      this.displaTrashCollection = displayTmwCollection.trash || displayTodayCollection.trash 
      if(displayTmwCollection.trash || displayTmwCollection.ridwell){
        this.choreTitle = 'Tomorrow'
        this.displayChores = true
      }else if(displayTodayCollection.trash || displayTodayCollection.ridwell){
        this.choreTitle = 'Today'
        this.displayChores = true
      }else {
        this.displayChores = false
      }
    },
    fetchhRidwellCollection() {
      var nextCollectionDate = LuxonDateTime.local().startOf('week').set({ weekday:  5 })
      if(nextCollectionDate.weekNumber % 2 == 0){
        nextCollectionDate = nextCollectionDate.plus({week: 1})
      }
      var nextRidwellCollection = {
        luxonStart: nextCollectionDate,
        plastics: true,
      }
      this.nextRidwellCollection = nextRidwellCollection
    },
    fetchWasteCollection() {
      var nextTrashCollection = {
        luxonStart: LuxonDateTime.local().startOf('week').set({ weekday: 2 }), // Set to Tues of current week
        garbage: true,
        compost: true,
        recycling: false,
      }
      
      if(nextTrashCollection.luxonStart.weekNumber % 2 == 0){
        nextTrashCollection.recycling = true
      }
      // console.log("Next trash collection: ", nextTrashCollection)

      if(nextTrashCollection !== undefined){
        this.nextTrashCollection = nextTrashCollection
        this.choresError = false
      }
    },
  },
  mounted: function () {
    this.$nextTick(function () {

    })
    const { pause, resume, isActive } = useIntervalFn(() => {
      this.fetchChores();
    },  60 * 60 * 1000, {immediateCallback: true});
  }
}
</script>

<style lang="scss">

</style>
