import Bowser from "bowser"

import * as Sentry from '@sentry/vue'

import Rollbar from 'rollbar'

import Vue from 'vue'
import Vuex from 'vuex'
import { createApp } from 'vue'

import store from '../src/store/'
import App from "../components/App"

import { library as faLibrary } from '@fortawesome/fontawesome-svg-core'
import { faSpinnerThird, faEngineWarning, faBan, faCircle, faCalendarAlt, faSolarPanel, faLeaf, faIndustryAlt, faPowerOff, faHome, faSack, faHistory, faWind, faSun as falSun, faSnowflake, faFan, faTint, faQuestionCircle as falQuestionCircle, faBolt, faAirConditioner as falAirConditioner } from '@fortawesome/pro-light-svg-icons'
import { faAirConditioner, faFireSmoke, faThermometerHalf, faBan as farBan } from '@fortawesome/pro-regular-svg-icons'
import { faCloudRain, faCloudShowersHeavy, faCloudShowers, faCloudDrizzle, faUmbrella, faSunrise } from '@fortawesome/pro-solid-svg-icons'
import { faCameraHome, faAtom, faCalendarDay, faCoffee, faSun, faMoonStars, faCalendarStar, faFlask, faFluxCapacitor, faSmoke, faHeadSideMask } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

const browser = Bowser.getParser(window.navigator.userAgent);
// console.log(browser)
const isValidBrowser = browser.satisfies({
  macos: {
    safari: ">=16",
    chrome: ">=116",
    firefox: '>51'
  },
  windows:{
    chrome: '>=79',
  },
  linux:{
    chrome: '>=116',
  },
  safari: '>=16',
  tablet: {
    safari: '>=16',
  },
  mobile: {
    safari: '>=16',
  },
 })
 if(("standalone" in window.navigator) &&
    window.navigator.standalone){
      // Do nothing
 }else if(!isValidBrowser){
   if (window.stop) {
        window.stop();
    }

    throw '';
 }


 

 import queryString from 'query-string'
var query = queryString.parse(window.location.search)

const app = createApp(App)

app.config.globalProperties.experiment = ("true" == query.experiment)
app.config.globalProperties.debug = ("true" == query.debug)

var isProdEnv = () => {
  return document.head.querySelector("meta[name=rails_env]").content === "production"
}

app.config.globalProperties.$rollbar = new Rollbar({
  accessToken: 'ae557686daeb4bb9bf423993088429ce',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: isProdEnv(),
  source_map_enabled: true,
  environment: isProdEnv() ? 'production' : 'development',
  payload: {
    client: {
         javascript: {
            code_version: '1.0'
         }
    }
  }
});

app.use(store)

app.config.errorHandler = (err, instance, info) => {
  instance.$rollbar.error(err);
  throw err; // rethrow
};

faLibrary.add(faSpinnerThird, faEngineWarning, faBan, faCircle, faCalendarAlt, faCloudRain, faCloudShowersHeavy, faCloudShowers, faCloudDrizzle, faUmbrella, faCameraHome, faAtom, faCalendarDay, faCoffee, faSun, faMoonStars, faSunrise, faCalendarStar, faFlask, faFluxCapacitor, faSolarPanel, faLeaf, faIndustryAlt, faHome, faPowerOff, faSack, faHistory, faFireSmoke, faSmoke, faWind, faHeadSideMask, faAirConditioner, falSun, faSnowflake, faFan, faTint, falQuestionCircle, faBolt, faThermometerHalf, farBan, falAirConditioner)
app.component('font-awesome-icon', FontAwesomeIcon)
  .component('font-awesome-layers', FontAwesomeLayers)
  .component('font-awesome-layers-text', FontAwesomeLayersText)


import axios from "axios"
axios.defaults.baseURL = 'http://localhost:3000'

// See https://v3-migration.vuejs.org/breaking-changes/filters.html
app.config.globalProperties.$filters = {
/**
 * Vue filter to round the decimal to the given place.
 * http://jsfiddle.net/bryan_k/3ova17y9/
 *
 * @param {String} value    The value string.
 * @param {Number} decimals The number of decimal places.
 */
'round': function(value, decimals) {
  if(!value) {
    value = 0;
  }

  if(!decimals) {
    decimals = 0;
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
},

/**
 * Vue filter to convert the given value to percent.
 * http://jsfiddle.net/bryan_k/qauf3qyh/
 *
 * @param {String} value    The value string.
 * @param {Number} decimals The number of decimal places.
 */
'percentage': function(value, decimals) {
  if(!value) {
    value = 0;
  }

  if(!decimals) {
    decimals = 0;
  }

  value = value * 100;
  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  value = value + '%';
  return value;
}
}

app.mount('#app')
