import { DateTime as LuxonDateTime, Duration as LuxonDuration } from 'luxon'
import Vue from 'vue'
import AirisNotifier from '../../airis-notifier'
const axios = require('axios')

let apiRoot = '/proxy/weather'

var weatherIDForLocation = (location) => {
  let lat = location.lat
  let lon = location.lon
  return `${lat}-${lon}`
}

var throttlePercent = 0.33
var throttleCount = Infinity

var getAltTemp = ( unit, temp ) => {
  if( unit === 'f' ) {
    return Math.round( ( 5.0 / 9.0 ) * ( temp - 32.0 ) );
  } else {
    return Math.round( ( 9.0 / 5.0 ) * temp + 32.0 );
  }
}

// shape: [{ id, quantity }]
const state = {
  storeState: {

  },
  stationState: {

  },
  weather: {

  },
  getWeatherID: weatherIDForLocation
}

// getters
const getters = {
  weather: (state) => (location) => {
    let weatherID = weatherIDForLocation(location)
    let weather = state.storeState[weatherID]

    return weather
  },

  shouldWeatherBeActive: (state,  getters, rootState) => (location) => {
    if(rootState.browser.hidden){
      if(rootState.app.debug){
        console.log("Browser not active, weather inactive", rootState.browser)
      }
      return false
    }else if(rootState.app.debug){
      console.log("Browser active, weather active", rootState.browser)
    }

    var timeNow = LuxonDateTime.local()
    let weatherID = weatherIDForLocation(location)
    if(state.weather[weatherID] === undefined || state.weather[weatherID] === null) {
      return true
    }

    var maxTime = timeNow.set({hour: 6})
    var minTime = timeNow.set({hour: 23 })
    if(timeNow < maxTime || timeNow > minTime){
      state.weather[weatherID].throttleCount += 1

      if (state.weather[weatherID].throttleCount >= Math.floor(1.0 / throttlePercent)){
        state.weather[weatherID].throttleCount = 0
        if(rootState.app.debug){
          console.log("Allowing thru throttle", Math.floor(1.0 / throttlePercent))
        }
        return true
      }
      if(rootState.app.debug){
        console.log("Throttling",state.weather[weatherID].throttleCount,  Math.floor(1.0 / throttlePercent))
      }
      return false
    }else {
      // state.weather[weatherID].throttleCount = 0
    }
    return true
  }
}



// actions
const actions = {
  watchWeather({ dispatch, commit }, location) {
    let weatherID = weatherIDForLocation(location)

    if(state.weather[weatherID] === undefined || state.weather[weatherID] === null) {
      commit('setup', {weatherID: weatherID, location: location})
      commit('weatherState', { weatherID: weatherID, opts: {
        location: location, refreshing: true, error: false, lastRefresh: LuxonDateTime.local() }})
      commit('stationState', { weatherID: weatherID, opts: {
        location: location, refreshing: true, error: false, lastRefresh: LuxonDateTime.local() }})
      return dispatch('updateWeather', location).then(() => {
        dispatch('updateWeatherStation', location).then(() => {
          dispatch('periodicWeatherUpdate', location)
        })
        })
    }

    return new Promise((resolve, reject) => { })
  },
  periodicWeatherUpdate({ dispatch, commit }, location) {
    return new Promise((resolve, reject) => {
      setInterval(() => {
        dispatch('updateWeather', location)
        dispatch('updateWeatherStation', location)
      }, 3 * 1000 * 60)
      resolve()
    })
  },
  updateWeather({ getters, commit }, location) {
    let weatherID = weatherIDForLocation(location)
    return new Promise((resolve, reject) => {
      if(getters.shouldWeatherBeActive(weatherID, null)) {

        commit('weatherState', { weatherID: weatherID, opts: {
          location: location, refresh: true, error: false, errorMessage:"", lastRefresh: LuxonDateTime.local() }})

        // Axios does not support jsonp queries
        axios.get(apiRoot,
          {
            params: {
              lat: location.lat,
              lon: location.lon,
              service: 'darksky'
            }
        })
        .then(function(axiosResponse) {
          var result = axiosResponse.data
          // console.log("Weather Result", weatherID, result)
          var weatherData = {}
          weatherData.rawAPIData = result;
          weatherData.temp = result.currently.temperature;
          weatherData.currently = result.currently.summary;
          weatherData.icon = result.currently.icon;
          weatherData.pressure = result.currently.pressure;
          weatherData.humidity = result.currently.humidity;
          weatherData.visibility = result.currently.visibility;
          weatherData.updated = result.currently.time;

          // Additional DarkWeather info
          weatherData.currently = result.currently

          weatherData.high = result.daily.data[ 0 ].temperatureHigh;
          weatherData.low = result.daily.data[ 0 ].temperatureLow;
          weatherData.sunrise = result.daily.data[ 0 ].sunriseTime;
          weatherData.sunset = result.daily.data[ 0 ].sunsetTime;
          weatherData.description = result.daily.data[ 0 ].summary;

          // weatherData.attributionlink = "https://darksky.net/";
          weatherData.unit = 'f';

          if( weatherData.unit === 'f' ) {
            weatherData.altunit = 'c';
          } else {
            weatherData.altunit = 'f';
          }

          weatherData.alt = {
            temp: getAltTemp( 'f', weatherData.temp ),
            high: getAltTemp( 'f', weatherData.high ),
            low: getAltTemp( 'f', weatherData.low )
          };

          weatherData.forecast = [];

          for( var i = 0; i < 7; i++ ) {
            var forecast = result.daily.data[ i ];
            forecast.date = forecast.time;
            forecast.summary = forecast.summary;
            forecast.high = forecast.temperatureHigh;
            forecast.low = forecast.temperatureLow;
            forecast.icon = forecast.icon;
            forecast.darkWeatherData = forecast;

            forecast.alt = {
              high: getAltTemp( 'f', forecast.temperatureHigh ),
              low: getAltTemp( 'f', forecast.temperatureLow )
            };

            weatherData.forecast.push( forecast );
          }

          let weather = {weatherID: weatherID, data: weatherData};
          commit('mutateWeatherInfo', weather)
          commit('weatherState', { weatherID: weatherID, opts: {
            location: location, refresh: false, error: false, lastRefresh: LuxonDateTime.local()} })
        })
        .catch(function(axiosError) {
          var error
          var errorData = ""
          var errorType = ""

          if (axiosError.isAxiosError && axiosError.response !== undefined) {
            error = axiosError.response
            errorData = error.data
            errorType = error.status
          }else{
            error = axiosError
          }
          var weatherError = { }

          if(error === undefined){
            AirisNotifier.error("Unknown Weather Retrieval Error", axiosError)
            weatherError.active = true
            weatherError.message = "Weather updates on hold"
          }else if(errorType == 500){
            AirisNotifier.error("AIRIS Weather Retrieval Error", axiosError)
            weatherError.active = true
            weatherError.message = "Weather update failed"
          }else if(errorData.message !== undefined && errorData.message.startsWith('API rate limit')) {
            weatherError.active = true
            weatherError.message = "Weather updates are paused"
          }else {
      		  AirisNotifier.error("Weather Retrieval Error", axiosError)
            weatherError.active = true
            weatherError.message = "Weather on pause"
          }
          commit('weatherState', { weatherID: weatherID, opts: {
            location: location, refresh: false, error: true, errorMessage: weatherError.message, lastRefresh: LuxonDateTime.local() }})
        }).then(function(){
          resolve()
        });

      }else{
        resolve()
      }
    })
  },
  updateWeatherStation({ getters, commit, rootState }, location) {
    let weatherID = weatherIDForLocation(location)
    return new Promise((resolve, reject) => {
      if(getters.shouldWeatherBeActive(weatherID, null)) {

        commit('stationState', { weatherID: weatherID, opts: {
          location: location, refresh: true, error: false, errorMessage:"", lastRefresh: LuxonDateTime.local() }})

        // Axios does not support jsonp queries
        axios.get(apiRoot + '_station',
          {
            params: {
              
            }
        })
        .then(function(axiosResponse) {
          // console.log("Weather Result", weatherID, result)
          var result = axiosResponse.data.current
          
          if(rootState.app.debug){
            console.log("Station Info Result", result)
          }
          var weatherData = {}
          weatherData.stationTemp = getAltTemp('c', parseFloat(result.tp))
          weatherData.humidity = result.hu

          weatherData.aqi = result.aqius
          weatherData.particulate = {
            pm25: result.pm25,
            pm10: result.pm10,
            pm1: result.pm1
          }
          weatherData.pressure = result.pr

          let weather = {weatherID: weatherID, data: weatherData};
          commit('mutateWeatherStationInfo', weather)
          commit('stationState', { weatherID: weatherID, opts: {
            location: location, refresh: false, error: false, lastRefresh: LuxonDateTime.local()} })
        })
        .catch(function(axiosError) {
          var error
          var errorData = ""
          var errorType = ""

          if (axiosError.isAxiosError && axiosError.response !== undefined) {
            error = axiosError.response
            errorData = error.data
            errorType = error.status
          }else{
            error = axiosError
          }
          var weatherError = { }

          if(error === undefined){
            AirisNotifier.error("Unknown Weather Retrieval Error", axiosError)
            weatherError.active = true
            weatherError.message = "Weather updates on hold"
          }else if(errorType == 500){
            AirisNotifier.error("AIRIS Weather Retrieval Error", axiosError)
            weatherError.active = true
            weatherError.message = "Weather update failed"
          }else if(errorData.message !== undefined && errorData.message.startsWith('API rate limit')) {
            weatherError.active = true
            weatherError.message = "Weather updates are paused"
          }else {
      		  AirisNotifier.error("Weather Retrieval Error", axiosError)
            weatherError.active = true
            weatherError.message = "Weather on pause"
          }
          commit('stationState', { weatherID: weatherID, opts: {
            location: location, refresh: false, error: true, errorMessage: weatherError.message, lastRefresh: LuxonDateTime.local() }})
        }).then(function(){
          resolve()
        });

      }else{
        resolve()
      }
    })
  }
}

// mutations
const mutations = {
  setup(state, weather){
    let weatherID = weather.weatherID
    let opts = { id: weatherID,
                 location: weather.location,
                 refresh: false,
                 error: false,
                 errorMessage: "",
                 lastRefresh: false,
                 lastStationRefresh: false,
                 throttleCount: Infinity,
               }
    if(state.weather[weatherID] === undefined || state.weather[weatherID] === null){
      state.weather[weatherID] = opts
    }
  },
  mutateWeatherInfo (state, weatherData) {
    let weatherID = weatherData.weatherID
    let weather = weatherData.data

    var today = LuxonDateTime.local()

    let opts = {weatherID: weatherID, weather: weather, station: null }
    if(state.weather[weatherID] === undefined || state.weather[weatherID] === null){
      state.weather[weatherID] = opts
    }else {
      state.weather[weatherID].weather = weather
    }
  },
  mutateWeatherStationInfo (state, weatherData) {
    let weatherID = weatherData.weatherID
    let weatherStation = weatherData.data

    var today = LuxonDateTime.local()

    let opts = {weatherID: weatherID, weather: null, station:  weatherStation}
    if(state.weather[weatherID] === undefined || state.weather[weatherID] === null){
      state.weather[weatherID] = opts
    }else {
      state.weather[weatherID].station = weatherStation
    }
  },
  weatherState (state, weather) {
    let weatherID = weather.weatherID
    let opts = weather.opts
    if(state.storeState[weatherID] === undefined || state.storeState[weatherID] === null){
      state.storeState[weatherID] = { refresh: false, error: false, errorMessage: "", lastRefresh: LuxonDateTime.local() }
    }

    if(opts.refresh !== undefined && opts.refresh !== null){
      state.storeState[weatherID].refresh = opts.refresh
    }
    if(opts.error !== undefined && opts.error !== null){
      state.storeState[weatherID].error = opts.error
    }
    if(opts.errorMessage !== undefined && opts.errorMessage !== null){
      state.storeState[weatherID].errorMessage = opts.errorMessage
    }
    if(opts.location !== undefined && opts.location !== null){
      state.storeState[weatherID].location = opts.location
    }
    if(opts.lastRefresh !== undefined && opts.lastRefresh !== null){
      state.storeState[weatherID].lastRefresh = opts.lastRefresh
    }
    if(opts.weather !== undefined && opts.weather !== null){
      state.storeState[weatherID].weather = opts.weather
    }

  },
  stationState (state, weather) {
    let weatherID = weather.weatherID
    let opts = weather.opts
    if(state.stationState[weatherID] === undefined || state.stationState[weatherID] === null){
      // debugger
      state.stationState[weatherID] = { refresh: false, error: false, errorMessage: "", lastRefresh: LuxonDateTime.local() }
    }

    if(opts.refresh !== undefined && opts.refresh !== null){
      state.stationState[weatherID].refresh = opts.refresh
    }
    if(opts.error !== undefined && opts.error !== null){
      state.stationState[weatherID].error = opts.error
    }
    if(opts.errorMessage !== undefined && opts.errorMessage !== null){
      state.stationState[weatherID].errorMessage = opts.errorMessage
    }
    if(opts.location !== undefined && opts.location !== null){
      state.stationState[weatherID].location = opts.location
    }
    if(opts.lastRefresh !== undefined && opts.lastRefresh !== null){
      state.stationState[weatherID].lastRefresh = opts.lastRefresh
    }
    if(opts.weather !== undefined && opts.weather !== null){
      state.stationState[weatherID].weather = opts.weather
    }

  }
}

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
