<template>
  <div class='container' :weather="weather" :station="station">
    <div v-if='weatherError.active' class='row'>
      <div class='col-md-12'>
        <div class='mx-auto'> <div class='icon title error'><font-awesome-icon :icon="['fal', 'engine-warning']" /> </div>
        <h4 style="display: inline">{{ weatherError.message }}</h4></div>
      </div>
    </div>
    <div v-if='currentWeather.populated' class='row'>
      <div class='col-md-12'>
        <div class='weather-report float-start'>
          <div class='current'>
            <div class='state'><i :class='`wi wi-${currentWeather.icon}`' ></i></div>
            <div class='temp'  v-if='currentWeather.populated' >{{$filters.round(currentWeather.temp)}}°</div>
          </div>
          <div class='forecast'>
            <div class='high'>{{$filters.round(currentWeather.high)}}°</div>
            <div class='low'>{{$filters.round(currentWeather.low)}}°</div>
          </div>
          <div class='current aqi' v-if='currentStation.populated && currentStation.aqi.state != "good"'>
              <div class='state'>
                <div class='icon' v-if='currentStation.aqi.state == "poor"'><font-awesome-icon :icon="['fad', 'smoke']" /></div>
                <div class='icon warning' v-if='currentStation.aqi.state == "bad"'><font-awesome-icon :icon="['fad', 'head-side-mask']" /></div>
                <div class='icon error' v-if='currentStation.aqi.state == "terrible"'><font-awesome-icon :icon="['far', 'fire-smoke']" /></div>
              </div>
              <div class='temp' >AQI<br/>{{$filters.round(currentStation.aqi.value)}}</div>
          </div>
        </div>
        <div class='weather-report float-end'>
          <div class='current'>
            <div class='state mr-4'><i :class='`wi wi-${currentWeather.forecast.next.icon}`' ></i></div>
          </div>
          <div class='forecast'>
            <div class='high'>{{$filters.round(currentWeather.forecast.next.high)}}°</div>
            <div class='low'>{{$filters.round(currentWeather.forecast.next.low)}}°</div>
          </div>
        </div>
        <div class='weather-report float-end' v-if='currentWeather.forecast.next.precipProbability > 0.1'>
          <div class='current secondary low' style='margin-top: 28px; margin-right: 24px;'>
            <font-awesome-icon :icon="['fa', getRainIcon(currentWeather.forecast.next.precipProbability)]" style='display:block; margin-bottom:10px' />
            <font-awesome-icon :icon="['fa', 'umbrella']" />
          </div>
        </div>
      </div>
    </div>
    <div v-if='currentWeather.populated' class='row secondary'>
      <div class='weather-report col-md-12' style='margin-top: 0px;'>
        <div class='current secondary' style="width:100%" >
          <div v-if='currentStation.populated && currentStation.aqi.state == "good"' class='secondary-num' style='margin-right:24px; display:inline-block; '>AQI {{$filters.round(currentStation.aqi.value)}}</div>
          <div v-if='currentWeather.windGust > 15.0'  class='secondary-num' style='margin-right:24px; display:inline-block; '><font-awesome-icon :icon="['fal', 'wind']" /> {{$filters.round(currentWeather.windSpeed)}}-{{$filters.round(currentWeather.windGust)}}<small>mph</small>
          </div>
          <transition name='fadeLeft' style="animation-duration: 5s"><span v-if='!showWeekSummary'>{{currentWeather.forecast.day.summary}} today</span></transition>
          <transition name='fadeRight' style="animation-duration: 5s"><span v-if='showWeekSummary' class='float-end' >{{currentWeather.forecast.week.summary}} this week</span></transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AirisNotifier from '../src/airis-notifier'
import { DateTime as LuxonDateTime, Duration as LuxonDuration } from 'luxon'
import { useStore } from 'vuex'

export default {
  name: 'weather',
  data: () => {
  return {
    location: {
      lat: 47.6708284,
      lon: -122.3853433,
    },
    currentWeather: {
      populated: false,
    },
    currentStation: {
      populated: false,
    },
    daytime: false,
    showWeekSummary: false,
    weatherError: {
      active: false,
      message: '',
    },
  }
  },
  computed: {
    station(){
      var weatherID = this.$store.state.weather.getWeatherID(this.location)
      this.weatherError.active = false

      var stationInfo = this.$store.state.weather.weather[weatherID].station
      var stationState = this.$store.state.weather.stationState[weatherID]
      if(stationState.lastRefresh !== undefined){
        this.weatherError.active = stationState.error
        this.weatherError.message = stationState.errorMessage
        if(stationState.error){
          return
        }

        if (stationInfo !== undefined){
          if(this.debug){
            console.log("Station", stationInfo)
          }
          this.weatherError.active = false

          var aqiState = null
          if(stationInfo.aqi !== undefined){
            if(stationInfo.aqi > 150){
              aqiState = 'terrible'  
            }else if(stationInfo.aqi > 100){
              aqiState = 'bad'
            }else if(stationInfo.aqi > 50){
              aqiState = 'poor'
            }else {
              aqiState = 'good'
            }
          }
          this.currentStation = {
            temp: stationInfo.temp,
            humidity: stationInfo.humidity,
            aqi: {
              value: stationInfo.aqi,
              state: aqiState,
            },
            pressure:  stationInfo.pressure,
            particulate: {
              p25: stationInfo.particulate.p25,
              p10: stationInfo.particulate.p10
            }
          }
          
          this.currentStation.populated = true
          if(this.debug){
            console.log("Current Weather", this.currentWeather)
          }
        }
      }
      return this.$store.state.weather.stationState[weatherID].lastRefresh
    },
    weather() {
      let weatherID = this.$store.state.weather.getWeatherID(this.location)
      this.weatherError.active = false

      let weatherData = this.$store.state.weather.weather[weatherID].weather
      let weatherState = this.$store.state.weather.storeState[weatherID]
      let weather = weatherData
      if(weatherState.lastRefresh !== undefined){
        this.weatherError.active = weatherState.error
        this.weatherError.message = weatherState.errorMessage
        // debugger
        if(weatherState.error){
          // if(this.debug){
            console.log("WeatherError", this.weatherError.message)
          // }
          return
        }

        if (weatherData !== undefined){
          if(this.debug){
            console.log("Weather", weather)
          }
          this.weatherError.active = false
          this.currentWeather = {}
          // this.currentWeather.icon = 'clear-night'
          this.currently = weather.currently
          // this.currently.windGust = 40
          this.currentWeather.temp = this.currently.temperature
          this.currentWeather.apparentTemperature = this.currently.apparentTemperature
          this.currentWeather.high = weather.high
          this.currentWeather.low = weather.low
          this.currentWeather.windSpeed = this.currently.windSpeed
          this.currentWeather.windGust = this.currently.windGust
          this.currentWeather.sunrise = weather.sunrise
          this.currentWeather.sunset = weather.sunset
          // debugger
          this.currentWeather.forecast = {
            immediate: weather.rawAPIData.minutely,
            day: weather.rawAPIData.hourly,
            week: weather.forecast,
            next: weather.forecast[1],
            today: weather.forecast[0],
          }

          this.currentWeather.icon = this.currentWeather.forecast.today.icon

          this.currentWeather.alerts = weather.alerts

          // this.currentWeather.forecast.next.windSpeed = this.currentWeather.forecast.next.darkWeatherData.windSpeed
          // this.currentWeather.forecast.next.windGust = this.currentWeather.forecast.next.darkWeatherData.windGust

          // console.log(weather)

          var sunrise = LuxonDateTime.fromSeconds(this.currentWeather.sunrise)
          var sunset = LuxonDateTime.fromSeconds(this.currentWeather.sunset)
          var currentTime = LuxonDateTime.local()
          // console.log(currentTime.toHTTP(), sunrise.toHTTP(), sunset.toHTTP())
          this.daytime = (currentTime > sunrise) && (currentTime < sunset)

          // Only show the week summary after sunset, or in the early hours of the morning
          // this.showWeekSummary = (currentTime >= sunset) || (currentTime <= sunrise.minus({ hours: 3 }))

          this.replaceIconLabels(this.currentWeather, this.daytime)
          // console.log("Forecast")
          this.replaceIconLabels(this.currentWeather.forecast.next, true)

          this.currentWeather.populated = true
          if(this.debug){
            console.log("Current Weather", this.currentWeather)
          }
        }
      }
      return this.$store.state.weather.storeState[weatherID].lastRefresh
    }
  },
  methods: {
    replaceIconLabels(weather, isDaytime) {
      var varyByTime = true
      var useWindIcon = false
      var supportsWindIcon = false
      // console.log(weather, weather.icon, isDaytime)

      var isWindy = (weather.windSpeed > 10)
      var isGusty = (weather.windGust - weather.windSpeed) > 15

      var windIcon = ''
      if(isGusty){
        windIcon = 'gusts'
        useWindIcon = true
      }else if(isWindy){
        windIcon = 'windy'
        useWindIcon = true
      }

      switch (weather.icon) {
        case 'partly-cloudy-day':
          weather.icon = 'cloudy'
          supportsWindIcon = true
          break
        case 'cloudy':
          varyByTime = false
          supportsWindIcon = true
          break
        case 'partly-cloudy-night':
          weather.icon = 'partly-cloudy'
          if(isDaytime){
            weather.icon = useWindIcon ? 'windy' : 'sunny'
          }
          supportsWindIcon = true
          break
        case 'rain':
          if(useWindIcon){
            weather.icon = 'rain-wind'
            useWindIcon = false
          }
          break
        case 'clear-day':
          weather.icon = 'sunny'
          weather.icon = useWindIcon ? 'windy' : 'sunny'
          useWindIcon = false
          break
        case 'clear-night':
        if(useWindIcon){
            weather.icon = 'strong-wind'
          }else {
            if(varyByTime && isDaytime){
              weather.icon = useWindIcon ? 'windy' : 'sunny'
            }else {
              weather.icon = 'stars'
            }
          }
          varyByTime = false
          useWindIcon = false
          break
        case 'sunny':
          weather.icon = useWindIcon ? 'windy' : 'sunny'
          useWindIcon = false
          break
      }

      if(useWindIcon && supportsWindIcon){
        weather.icon = `${weather.icon}-${windIcon}`
      }
      if(varyByTime){
        weather.icon = `${isDaytime ? "day" : "night-alt"}-${weather.icon}`
      }
    },
    getRainIcon(percent) {
      let icon = ''
      if(percent > 0.8){
        icon = 'cloud-rain'
      }else if(percent > 0.6){
        icon = 'cloud-showers-heavy'
      }else if(percent > 0.4){
        icon = 'cloud-showers'
      }else if(percent >= 0.1){
        icon = 'cloud-drizzle'
      }
      return icon
    },
},
created() {
  const store = useStore()
  store.dispatch('weather/watchWeather', this.location)
  this.$nextTick(function () {

  })
},
setup () {
    const store = useStore()
  },
}
</script>

<style lang="scss">

</style>
