
// import mqtt from 'mqtt';

import AirisNotifier from '../../airis-notifier'
import { DateTime as LuxonDateTime, Duration as LuxonDuration } from 'luxon'
import Vue from 'vue'


// import * as mqtt from "mqtt";
// import { MqttClient, IClientOptions, IClientPublishOptions, IClientSubscribeOptions } from 'mqtt';
// // type QoS = IClientPublishOptions['qos'];
// import { reactive, ref } from "vue";

// // Start: https://github.com/emqx/MQTT-Client-Examples/blob/master/mqtt-client-Vue3.js/src/components/MqttDemo.vue
// // License MIT: https://github.com/emqx/MQTT-Client-Examples/?tab=MIT-1-ov-file#readme
// // https://github.com/mqttjs/MQTT.js#qos
// const qosList = [0, 1, 2];

// /**
//  * this demo uses EMQX Public MQTT Broker (https://www.emqx.com/en/mqtt/public-mqtt5-broker), here are the details:
//  *
//  * Broker host: broker.emqx.io
//  * WebSocket port: 8083
//  * WebSocket over TLS/SSL port: 8084
//  */
// const connection = reactive({
//   // ws or wss
//   protocol: "ws",
//   host: "broker.emqx.io",
//   // ws -> 8083; wss -> 8084
//   port: 8083,
//   clientId: "emqx_vue3_" + Math.random().toString(16).substring(2, 8),
//   /**
//    * By default, EMQX allows clients to connect without authentication.
//    * https://docs.emqx.com/en/enterprise/v4.4/advanced/auth.html#anonymous-login
//    */
//   // username: "emqx_test",
//   // password: "emqx_test",
//   clean: true,
//   connectTimeout: 30 * 1000, // ms
//   reconnectPeriod: 4000, // ms
//   // for more options and details, please refer to https://github.com/mqttjs/MQTT.js#mqttclientstreambuilder-options
// });

// // topic & QoS for MQTT subscribing
// const subscription = ref({
//   topic: "topic/mqttx",
//   qos: 0
// });

// // topic, QoS & payload for publishing message
// const publish = ref({
//   topic: "topic/browser",
//   qos: 0,
//   payload: '{ "msg": "Hello, I am browser." }',
// });

// let client = ref({
//   connected: false,
// });
// const receivedMessages = ref("");
// const subscribedSuccess = ref(false);
// const btnLoadingType = ref("");
// const retryTimes = ref(0);

// const initData = () => {
//   client.value = {
//     connected: false,
//   };
//   retryTimes.value = 0;
//   btnLoadingType.value = "";
//   subscribedSuccess.value = false;
// };

// const handleOnReConnect = () => {
//   retryTimes.value += 1;
//   if (retryTimes.value > 5) {
//     try {
//       client.value.end();
//       initData();
//       console.log("connection maxReconnectTimes limit, stop retry");
//     } catch (error) {
//       console.log("handleOnReConnect catch error:", error);
//     }
//   }
// };

// // create MQTT connection
// const createConnection = () => {
//   try {
//     btnLoadingType.value = "connect";
//     const { protocol, host, port, ...options } = connection;
//     const connectUrl = `${protocol}://${host}:${port}/mqtt`;

//     /**
//      * if protocol is "ws", connectUrl = "ws://broker.emqx.io:8083/mqtt"
//      * if protocol is "wss", connectUrl = "wss://broker.emqx.io:8084/mqtt"
//      * 
//      * /mqtt: MQTT-WebSocket uniformly uses /path as the connection path,
//      * which should be specified when connecting, and the path used on EMQX is /mqtt.
//      * 
//      * for more details about "mqtt.connect" method & options,
//      * please refer to https://github.com/mqttjs/MQTT.js#mqttconnecturl-options
//      */
//     client.value = mqtt.connect(connectUrl, options);

//     if (client.value.on) {
//       // https://github.com/mqttjs/MQTT.js#event-connect
//       client.value.on("connect", () => {
//         btnLoadingType.value = "";
//         console.log("connection successful");
//       });

//       // https://github.com/mqttjs/MQTT.js#event-reconnect
//       client.value.on("reconnect", handleOnReConnect);

//       // https://github.com/mqttjs/MQTT.js#event-error
//       client.value.on("error", (error) => {
//         console.log("connection error:", error);
//       });

//       // https://github.com/mqttjs/MQTT.js#event-message
//       client.value.on("message", (topic, message) => {
//         receivedMessages.value = receivedMessages.value.concat(
//           message.toString()
//         );
//         console.log(`received message: ${message} from topic: ${topic}`);
//       });
//     }
//   } catch (error) {
//     btnLoadingType.value = "";
//     console.log("mqtt.connect error:", error);
//   }
// };

// // subscribe topic
// // https://github.com/mqttjs/MQTT.js#mqttclientsubscribetopictopic-arraytopic-object-options-callback
// const doSubscribe = () => {
//   btnLoadingType.value = "subscribe";
//   const { topic, qos } = subscription.value;
//   client.value.subscribe(
//     topic,
//     { qos },
//     (error, granted) => {
//       btnLoadingType.value = "";
//       if (error) {
//         console.log("subscribe error:", error);
//         return;
//       }
//       subscribedSuccess.value = true;
//       console.log("subscribe successfully:", granted);
//     }
//   );
// };

// // unsubscribe topic
// // https://github.com/mqttjs/MQTT.js#mqttclientunsubscribetopictopic-array-options-callback
// const doUnSubscribe = () => {
//   btnLoadingType.value = "unsubscribe";
//   const { topic, qos } = subscription.value;
//   client.value.unsubscribe(topic, { qos }, (error) => {
//     btnLoadingType.value = "";
//     subscribedSuccess.value = false;
//     if (error) {
//       console.log("unsubscribe error:", error);
//       return;
//     }
//     console.log(`unsubscribed topic: ${topic}`);
//   });
// };

// // publish message
// // https://github.com/mqttjs/MQTT.js#mqttclientpublishtopic-message-options-callback
// const doPublish = () => {
//   btnLoadingType.value = "publish";
//   const { topic, qos, payload } = publish.value;
//   client.value.publish(topic, payload, { qos }, (error) => {
//     btnLoadingType.value = "";
//     if (error) {
//       console.log("publish error:", error);
//       return;
//     }
//     console.log(`published message: ${payload}`);
//   });
// };

// // disconnect
// // https://github.com/mqttjs/MQTT.js#mqttclientendforce-options-callback
// const destroyConnection = () => {
//   if (client.value.connected) {
//     btnLoadingType.value = "disconnect";
//     try {
//       client.value.end(false, () => {
//         initData();
//         console.log("disconnected successfully");
//       });
//     } catch (error) {
//       btnLoadingType.value = "";
//       console.log("disconnect error:", error);
//     }
//   }
// };

// const handleProtocolChange = (value) => {
//   connection.port = value === "wss" ? 8084 : 8083;
// };

// // End: https://github.com/emqx/MQTT-Client-Examples/blob/master/mqtt-client-Vue3.js/src/components/MqttDemo.vue
const state = {
  subscribedClients: { },
};

const getters = {
//   getClientTopics: (state) => (clientId) => {
//     const client = state.clients.find((c) => c.id === clientId);
//     return client ? client.topics : [];
//   },
};

const actions = {
  async subscribe({ dispatch, commit }) {
    var connected = await $mqtt.connect();
    if(connected){
      console.log("Climate Store", "MQTT Connected")
      await $mqtt.subscribe(`climate/#`, (data) => {
        if(rootState.app.debug){
          console.log('MQTT Climate Minisplit Data', data);
        }
      })
      commit('subscribed');
    }
    
    return new Promise((resolve, reject) => { })
  },

  async unsubscribe({ dispatch, commit }) {
    var connected = await $mqtt.connect();
    if(connected){
      await $mqtt.unsubscribesubscribe(`climate/#`)
      commit('unsubscribed');
    }
  },

  watchMinisplit({ dispatch, commit }, clientID) {
    // if(!$mqtt.connected){
    //   var connected = await $mqtt.connect();
    // }
    // $mqtt.connect({
    //   onConnect: () => {
    //     console.log('Mqtt connected');
    //   },
    //   onFailure: () => {
    //     console.log('Mqtt connection failed');
    //   },
    //   onConnectionLost: (error) => {
    //     console.log('Error:', error);
    //   },
    //   onMessageArrived: (message) => {
    //     console.log(
    //       'Message Arrived:',
    //       message,
    //       message.payloadString,
    //       message.destinationName,
    //     );
    //   },
    // });
    commit('addMinisplitClient', clientID);
    var topic = `climate/${clientID}`;
    console.log("Climate Store", "Subscribing to Minisplit", clientID, topic)
    
    $mqtt.subscribe(topic, (data, ...args) => {
      console.log('MQTT Climate Minisplit Data', clientID, data);
      if(rootState.app.debug){
        console.log('MQTT Climate Minisplit Data', clientID, data);
      }
    })
  }
};

const mutations = {
  subscribed(state){

  },
  unsubscribed(state){

  },
  addMinisplitClient(state, clientID) {
    if(state.subscribedClients[clientID] === undefined){
      state.subscribedClients[clientID] = {
        current_temperature: null,
        target_temperature: null,
        fan_mode: null,
        hvac_mode: null,
        swing_mode: null,
        lastUpdate: null,
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
