import Rollbar from 'rollbar'

var isProdEnv = () => {
  return document.head.querySelector("meta[name=rails_env]").content === "production"
}

const RollbarInstance = new Rollbar({
  accessToken: 'ae557686daeb4bb9bf423993088429ce',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: isProdEnv(),
  source_map_enabled: true,
  environment: isProdEnv() ? 'production' : 'development',
  payload: {
    client: {
         javascript: {
            code_version: '1.0'
         }
    }
  }
});
var rollbarEnabled = (Rollbar !== undefined && Rollbar !== null && RollbarInstance.options.enabled)

var AirisNotifier = {
  error(errMessage){
    let args = Array.from(arguments)
    console.error(...args)
    if(rollbarEnabled){
      RollbarInstance.error.apply(RollbarInstance, args)
    }
  },
  warn(warnMessage){
    let args = Array.from(arguments)
    console.warn(...args)
    if(rollbarEnabled){
      RollbarInstance.warning.apply(RollbarInstance, args)
    }
  },
  info(logMessage){
    let args = Array.from(arguments)
    console.log(...args)
    if(rollbarEnabled){
      RollbarInstance.info.apply(RollbarInstance, args)
    }
  },
  log(logMessage){
    let args = Array.from(arguments)
    console.log(...arguments)
    if(rollbarEnabled){
      RollbarInstance.debug.apply(RollbarInstance, args)
    }
  },
}

export default AirisNotifier
