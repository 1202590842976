<template>
  <transition name="fadeRight">
<div v-if='transitDisplayActive' class='transit container' style="animation-duration: 10s">
  <div v-if='fetchingData' class='transit-title float-start'>{{routeName}}</div>
  <transition name="fadeRight">
    <div v-if='fetchingData.active' class='icon title float-start'><font-awesome-icon :icon="['fal', 'spinner-third']" spin /></div>
  </transition>
  <transition name='fadeRightBig' style="animation-duration: 1s">
    <div v-if='fetchingData.error' class='icon title error float-start'><font-awesome-icon :icon="['fal', 'engine-warning']" /></div>
  </transition>
  <div :routeDepartures="routeDepartures" class="clearfix"> </div>
  <transition-group name="fadeUp" tag='div' class='departures'>
    <div v-for='departure in upcomingDepartures' v-bind:key="departure.key" class='bus' :style='`color:${departure.colorFill}`' ><div class='icon-bus' > </div>
      <div class='departure-time'>{{departure.nextDepatureFromNow}}</div>
      <font-awesome-icon class='scheduled' v-if='!departure.live' :icon="['fal', 'calendar-alt']" />
      <div class='schedule-delay' v-if='departure.schedule_deviation_min > 0.8'>{{departure.schedule_deviation_min}}m delay...</div>
    </div>
    </transition-group>
    <h4 v-if='!upcomingDepartures.length'>
      <i>hang on, my guy is<br/>getting back to me...</i>
    </h4>
</div>
</transition>
</template>
<script>
import "jsonp"
import moment from "moment"
import { DateTime as LuxonDateTime, Duration as LuxonDuration } from 'luxon'
import AirisNotifier from '../src/airis-notifier'
import { useStore } from 'vuex'

import { useIntervalFn } from '@vueuse/core'

export default {
  name: 'transit',
  props: {
    routeName: String,
    routeId: String,
    stopId: String,
    lateMin: {
      type: Number,
      default: 4
    },
    warningMin: {
      type: Number,
      default: 7
    },
    leaveMin: {
      type: Number,
      default: 10
    },
  },
  data: () => {
  return {
    currentMoment: moment(),
    transitTimers: [],
    upcomingDepartures: [],
    transitDisplayActive: true,
  }
  },
  methods: {
    displayTransit() {
      this.transitDisplayActive = this.$store.getters["transit/shouldTransitBeActive"](this.stopId, this.routeId)
    },
  },
  computed: {
    routeDepartures () {
      this.transitDisplayActive = this.$store.getters["transit/shouldTransitBeActive"](this.stopId, this.routeId)

      if(this.transitDisplayActive){
        // console.log("Filtering Upcoming Departures", this.routeId)
        let upcomingDeparturesFullList = new Array();
        upcomingDeparturesFullList = this.$store.getters["transit/stopDeparturesForRoute"](this.stopId, this.routeId)
        let upcomingDeparturesValid = new Array();
        upcomingDeparturesFullList = upcomingDeparturesFullList.slice(0,3)
        _.each(upcomingDeparturesFullList, (departure)=>{
          // console.log(departure);

          let departureTime = null
          departure['live'] = false
          if(departure.predicted){
            departureTime = moment(departure.predictedArrivalTime);
            departure['live'] = true
            var tripStatus = departure.tripStatus
            departure['schedule_deviation_min'] = Math.round(tripStatus.scheduleDeviation / 60.0)
          }else {
            departureTime = moment(departure.scheduledArrivalTime);
          }
          departure['nextDepatureFromNow'] = departureTime.fromNow(true);
          departure.key = departure.tripId;

          let currentTimeMS = (new Date).getTime();
          let timeDiff = departureTime - moment.now() //currentTimeMS;
          let waitDuration = moment.duration(timeDiff).asMinutes();
          // let currentTime = moment();

          if(departure.predictedArrivalTime <= 0){
            waitDuration = this.leaveMin * 10;
            // departure['nextDepatureFromNow'] = _.sample(["a while…", "tad confused…", "not sure…", "wanderer…", "on walkabout…", "itsasecret", "partybus don't stop!", "halp."]);
          }

          let color = '#FFF';
          if(waitDuration < this.lateMin){
            color = '#FC6042';
          }else if(waitDuration <= this.warningMin){
            color = '#E8AA14';
          }else if(waitDuration <= this.leaveMin){
            color = '#6EEB83';
          }

          departure.colorFill = color;
          upcomingDeparturesValid.push(departure)
        })
        this.upcomingDepartures = upcomingDeparturesValid
      }
      // console.log("Transit State", store.state.transit.stopState[this.stopId])
      return this.$store.state.transit.stopState[this.stopId].lastRefresh
    },
    fetchingData () {
      const store = useStore()
      // console.log("Fetching", store.state.transit.stopState[this.stopId])
      return this.$store.state.transit.stopState[this.stopId]
    },
  },
  created() {
    const store = useStore()
    const { pause, resume, isActive } = useIntervalFn(() => {
      this.displayTransit();
    },  1000 * 60)
    store.dispatch('transit/watchStop', this.stopId)
  },
  setup () {
    
  },
  beforeUnmount(){
    // AirisNotifier.log("Clearing Transit Timers")
    // this.transitTimers.forEach((timer) => {
    //   timer.clear()
    // })
  }
}
</script>

<style lang="scss">

</style>
