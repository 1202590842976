import { DateTime as LuxonDateTime, Duration as LuxonDuration } from 'luxon'
import Vue from 'vue'
import AirisNotifier from '../../airis-notifier'

let apiRoot = '//api.pugetsound.onebusaway.org/api/'

// shape: [{ id, quantity }]
const state = {
  stopState: {

  },
  stopDepartures: {

  },
}

// getters
const getters = {
  stopDeparturesForRoute: (state) => (stopID, routeID) => {
    let departures = state.stopDepartures[stopID]
    let localRouteID = routeID
    let routeDepartures = _.filter(departures, (value)=> {
        return (value.routeId == localRouteID)
      })
    return routeDepartures
  },
  shouldTransitBeActive: (state, getters, rootState) => (stopID, routeID) => {
    if(rootState.browser.hidden){
      if(rootState.app.debug){
        console.log("Browser not active, transit inactive", rootState.browser)
      }
      return false
    }

    var debugDay = false
    var debugTime = false
    var timeNow = LuxonDateTime.local()
    if(timeNow.weekday > 5){ // Only run during week
      if(!debugDay){
        return false
      }else{
        if(rootState.app.debug){
          console.warn("Allowing Transit Active Due to DebugDay, despite day of week", timeNow.weekday)
        }
      }
    }
    var maxTime = timeNow.set({hour: 10, minute: 15, second: 0})
    var minTime = timeNow.set({hour: 7, minute: 30, second: 0})
    if(timeNow < maxTime && timeNow > minTime){
      return true
    }
    if(debugTime){
      console.warn("Allowing Transit Active Due to DebugTime, despite time of day", timeNow, maxTime, minTime)
      return true
    }
    if(debugDay){
      console.log(`Transit Not Active: ${minTime.toLocaleString(LuxonDateTime.TIME_WITH_SECONDS)} -> ${maxTime.toLocaleString(LuxonDateTime.TIME_WITH_SECONDS)} for ${timeNow.toLocaleString(LuxonDateTime.TIME_WITH_SECONDS)}`)
    }
    return false
  },
}

// actions
const actions = {
  watchStop({ dispatch, commit }, stopID) {
    if(state.stopState[stopID] === undefined || state.stopState[stopID] === null) {
      commit('updateStopDepartures', { stopID: stopID, departures: [] })
      commit('transitStopState', { stopID: stopID, opts: { active: false, error: false }})
      return dispatch('updateTransit', stopID).then(() => {
        dispatch('periodicTransitUpdate', stopID)
      })
    }

    return new Promise((resolve, reject) => { })
  },
  periodicTransitUpdate({ dispatch, commit }, stopID) {
    return new Promise((resolve, reject) => {
      setInterval(() => {
        dispatch('updateTransit', stopID)
      }, 1000 * 20)
      resolve()
    })
  },
  updateTransit({ getters, commit }, stopID) {
    return new Promise((resolve, reject) => {
      // console.log("Attempting Transit Fetch")
      if(getters.shouldTransitBeActive(stopID)) {

        commit('transitStopState', { stopID: stopID, opts: { active: true, error: false }})
        // console.log("Fetching Transit Stop", stopID)
        // Axios does not support jsonp queries
        jQuery.ajax({
        url: apiRoot + 'where/arrivals-and-departures-for-stop/' + stopID + '.json',
        jsonp: "callback",
        dataType: "jsonp",
        data: {
          key: 'f5c011dc-b2ef-4fae-ae9f-235b0c400e7c',
          'minutesBefore': 0,
          'minutesAfter': 45,
        }
        })
        .done(function(data) {
          // console.log("Transit Result", stopID, data)
          let arrivalsAndDepartures = data.data.entry.arrivalsAndDepartures;
          // console.log(`Stop: ${stopID}`, arrivalsAndDepartures)
          commit('updateStopDepartures', { stopID: stopID, departures: arrivalsAndDepartures })
          commit('transitStopState', { stopID: stopID, opts: { active: false, error: false, lastRefresh: LuxonDateTime.local()} })
          resolve()
          // console.log("Finished Transit Fetch", stopID)
        })
        .fail(function(error) {
          AirisNotifier.error( "Pudget Sound API Transit Retrieval Error", error);
          commit('transitStopState', { stopID: stopID, opts: { active: false, error: true, lastRefresh: LuxonDateTime.local() }})
          reject()
        });
      }else{
        resolve()
      }
    })
  }
}

// mutations
const mutations = {
  updateStopDepartures (state, stopDepartures) {
    let stopID = stopDepartures.stopID
    let departuresList = stopDepartures.departures
    
    state.stopDepartures[stopID] = departuresList
  },
  transitStopState (state, stopState) {
    let stopID = stopState.stopID
    let opts = stopState.opts
    if(state.stopState[stopID] === undefined || state.stopState[stopID] === null){
      state.stopState[stopID] = { active: false, error: false, lastRefresh: LuxonDateTime.local() }
    }

    if(opts.active !== undefined && opts.active !== null){
      state.stopState[stopID].active = opts.active
    }
    if(opts.error !== undefined && opts.error !== null){
      state.stopState[stopID].error = opts.error
    }

    if(opts.lastRefresh !== undefined && opts.lastRefresh !== null){
      state.stopState[stopID].lastRefresh = opts.lastRefresh
    }

  }
}

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
