<template>
  <transition name="fadeRight">
<div v-if='calendarDisplayActive' class='calendar' style="animation-duration: 10s">
  <div v-if='fetchingData' class='title float-start'><font-awesome-icon :icon="['fad', 'calendar-day']" /> {{calendarGrouping}}</div>
  <transition name="fadeRight">
    <div v-if='fetchingData.active' class='icon title float-start'><font-awesome-icon :icon="['fal', 'spinner-third']" spin /></div>
  </transition>
  <transition name='fadeRightBig' style="animation-duration: 1s">
    <div v-if='fetchingData.error' class='icon title error float-start'><font-awesome-icon :icon="['fal', 'engine-warning']" /></div>
  </transition>
  <div :calendarEvents="calendarEvents" class="clearfix"> </div>
  <transition-group name="fadeUp" tag='div' class='departures'>
    <div v-for='event in events' v-bind:key="event.id" class='event' >
      <div>
        <font-awesome-icon :icon="['fas', 'sunrise']" v-if="event.timeOfDay.early" />
        <font-awesome-icon :icon="['fad', 'coffee']" v-if="event.timeOfDay.morning" />
        <font-awesome-icon :icon="['fad', 'sun']" v-if="event.timeOfDay.afternoon" />
        <font-awesome-icon :icon="['fad', 'moon-stars']" v-if="event.timeOfDay.evening" />
        {{event.startLuxonDateTime.toLocaleString(timeFormat)}}
      </div>
      <div class='summary'>{{event.summary}}</div>
    </div>
  </transition-group>
  <div v-if='fetchingData.error' class="event">
    <h4 class='error'><i>{{fetchingData.errorMessage}}</i></h4>
  </div>
  <div v-else class="event" >
    <div v-if='!calendarEvents.length' >
    <font-awesome-icon :icon="['fad', 'calendar-star']" /> <i>You're free!</i>
  </div>
  </div>

</div>
</transition>
</template>
<script>
import "jsonp"
import moment from "moment"
import { DateTime as LuxonDateTime, Duration as LuxonDuration } from 'luxon'
import AirisNotifier from '../src/airis-notifier'
import { useStore } from 'vuex'
import { useIntervalFn } from '@vueuse/core'

let lateMin = 4;
let warningMin = 7;
let leaveMin = 10;

export default {
  name: 'calendar',
  props: {
    calendarGrouping: String,
    calendarIds: Array,
  },
  data: () => {
  return {
    currentMoment: moment(),
    calendarDisplayActive: true,
    events: [],
    timeFormat: LuxonDateTime.TIME_SIMPLE,
  }
  },
  timers: {
    displayCalendar: { time: 30000, immediate: true, autostart: true, repeat: true }
  },
  methods: {
    displayCalendar() {
      this.calendarDisplayActive = this.$store.getters["calendar/shouldCalendarBeActive"](this.calendarGrouping)
    },
  },
  computed: {
    calendarEvents () {
      this.calendarDisplayActive = this.$store.getters["calendar/shouldCalendarBeActive"](this.calendarGrouping)

      if(this.calendarDisplayActive){
        // console.log("Filtering Upcoming Departures")
        let events = new Array();
        events = store.getters["calendar/events"](this.calendarGrouping)

        this.events = events
      }
      return store.state.calendar.calendars[this.calendarGrouping].events
    },
    fetchingData () {
      // console.log("Fetching", store.state.calendar.stopState[this.calendarGrouping])
      return this.$store.state.calendar.calendarState[this.calendarGrouping]
    },
  },
  created() {
    const store = useStore()
    store.dispatch('calendar/watchCalendar', {calendarGrouping: this.calendarGrouping, calendarIDs: this.calendarIds})
    const { pause, resume, isActive } = useIntervalFn(() => {
      this.displayCalendar();
    },  1000 * 30)
  },
  beforeUnmount(){

  },
  setup () {
    // const store = useStore()
  },
}
</script>

<style lang="scss">

</style>
