<template>
<div class='face-detector'>
  <div class='row mt-2 text-center' v-if='faceDetectionSystemState.starting && showStarting' :stateEvent='faceDetectionSystemStateEvent'>
    <div class='col-md-12' style='font-size:2em;'>
      <font-awesome-icon :icon="['fad', 'atom']" spin style='margin-right:12px;'/> {{faceDetectionSystemState.startingState.message}}
    </div>
  </div>
  <transition name='fadeDown' style="animation-duration: 1s">
    <div class='row mt-2 text-center' v-if='faceDetectionSystemState.error && showError'
    :stateEvent='faceDetectionSystemStateEvent'>
      <div class='col-md-12'>
        <div class='icon title error'><font-awesome-icon :icon="['fad', 'camera-home']" style='margin-right:12px; font-size:1.3em;'/> {{faceDetectionSystemState.startingState.message}}
        </div>
      </div>
    </div>
  </transition>
  <div class='row'>
  <div class='col-md-12' v-bind:style="{ opacity: debugOpacity}">
      <video id="inputVideo" class='face-video' autoplay="true" preload="auto" muted="true" playsinline="true"></video>
      <canvas id="overlay" class='face-video' />
  </div>
  </div>
  <div class='row mt-4'>
  <div class='col-md-12 text-center' v-if='detectionEvent' >
      <div v-if='faceStatus.present' class='icon face-status'> 🤩 x{{faceStatus.count}}</div>
      <div v-else class='icon face-status error float-start'><font-awesome-icon :icon="['fal', 'circle']" /></div>
      <template  v-for='face in faceDetections'>
        <div class='subtitle' v-if='face.detection.airis.detected' v-bind:key="face.detection.faceMatch.label">{{face.detection.faceMatch.label}}: {{$filters.percentage(face.detection.faceMatch.distance)}}</div>
      </template>
  </div>
</div>
</div>

</template>

<script>
import AirisNotifier from '../src/airis-notifier'
import { useStore } from 'vuex'

export default {
  name: 'face-detector',
  data: () => {
  return {
    faceDetections: [],
    faceStatus: {
      present: false,
      count: 0,
    },
    showError: true,
    showStarting: true,
    faceDetectionSystemState: {
      starting: true,
      startingState: {
        message: 'Booting Up'
      }
    },
    debugOpacity: 0.0,
    }
  },
  computed: {
    detectionEvent () {
      this.faceDetections = store.getters["faceDetector/faces"]()
      this.faceStatus.present = store.state.faceDetector.present
      this.faceStatus.count = store.state.faceDetector.faceCount
      return store.state.faceDetector.present
    },
    faceDetectionSystemStateEvent () {
      this.faceDetectionSystemState = store.getters["faceDetector/systemState"]()
      this.showError = true
      let that = this

      if(this.showStarting && !this.faceDetectionSystemState.starting){
        setTimeout(function() {
          that.showStarting = false
        }, 5000)
      }

      if(this.faceDetectionSystemState.error){
        setTimeout(function() {
          that.showError = false
        }, 5000)
      }

      return store.state.faceDetector.systemState
    },
  },
  methods: {

  },
  mounted: function () {
    if(this.debug){
      this.debugOpacity = 1.0
    }
    const store = useStore()
    var faceDetection = store.dispatch("faceDetector/start", 'foo')
    this.$nextTick(function () {

    })
  },
  setup () {
    const store = useStore()
  },
}
</script>

<style lang="scss">
  .face-status {
    font-size: 3em;
    opacity: 0.4;
  }
  .face-video {
    position:fixed;
    top:0;
    right: 0;

    &canvas {
      position:fixed;
      top:0;
      right: 0;
      width: 640px;
      height: 480px;
    }
  }
</style>
