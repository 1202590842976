import { DateTime as LuxonDateTime, Duration as LuxonDuration } from 'luxon'
import Vue from 'vue'
import AirisNotifier from '../../airis-notifier'
const axios = require('axios')

let apiRoot = '/cal/'
let debugEventWindow = false

const state = {
  calendars: {

  },
  calendarState: {

  },
}

// getters
const getters = {
  events: (state) => (calendarGrouping) => {
    let events = state.calendars[calendarGrouping].events
    return events
  },
  calendarIDsForCalendar: (state) => (calendarGrouping) => {
    let calendarIDs = state.calendars[calendarGrouping].calendarIDs

    return calendarIDs
  },
  shouldCalendarBeActive: (state, getters, rootState) => (calendarGrouping) => {
    if(rootState.browser.hidden){
      if(rootState.app.debug){
        console.log("Browser not active, calendar inactive", rootState.browser)
      }
      return false
    }

    var debugDay = false
    var debugTime = true
    var timeNow = LuxonDateTime.local()

    var maxTime = timeNow.set({hour: 23, minute: 0})
    var minTime = timeNow.set({hour: 5, minute: 30})
    if(timeNow < maxTime && timeNow > minTime){
      return true
    }
    if(debugTime){
      console.warn("Allowing Calendar Active Due to DebugTime, despite time of day", timeNow, maxTime, minTime)
      return true
    }
    if(debugDay){
      console.log(`Calendar Not Active: ${minTime} -> ${maxTime} for ${timeNow}`)
    }
    return false
  },
}

// actions
const actions = {
  watchCalendar({ dispatch, commit }, calendar) {
    let calendarGrouping = calendar.calendarGrouping
    let calendarIDs = calendar.calendarIDs

    if(state.calendars[calendarGrouping] === undefined || state.calendars[calendarGrouping] === null) {
      commit('setupCalendar', { calendarGrouping: calendarGrouping, calendarIDs: calendarIDs})
      commit('calendarState', { calendarGrouping: calendarGrouping, opts: { refreshing: true, error: false, lastRefresh: LuxonDateTime.local() }})
      return dispatch('updateCalendar', calendarGrouping).then(() => {
        dispatch('periodicCalendarUpdate', calendarGrouping)
      })
    }

    return new Promise((resolve, reject) => { })
  },
  periodicCalendarUpdate({ dispatch, commit }, calendarGrouping) {
    return new Promise((resolve, reject) => {
      setInterval(() => {
        dispatch('updateCalendar', calendarGrouping)
      }, 1000 * 60)
      resolve()
    })
  },
  updateCalendar({ getters, commit }, calendarGrouping) {
    return new Promise((resolve, reject) => {
      if(getters.shouldCalendarBeActive(calendarGrouping, null)) {
        let calendarIDs = getters.calendarIDsForCalendar(calendarGrouping, null)
        commit('calendarState', { calendarGrouping: calendarGrouping, opts: { refresh: true, error: false, errorMessage:"", lastRefresh: LuxonDateTime.local() }})
        calendarIDs.forEach((calendarID) => {

        // console.log("Fetching Transit Stop", stopID)
        // Axios does not support jsonp queries
        axios.get(apiRoot + 'events',{
          params: {
            calID: calendarID,
          }
        })
        .then(function(result) {
          var data = result.data
          // console.log("Calendar Result", calendarID, data)
          let events = data.events;
          // console.log(`Stop: ${stopID}`, arrivalsAndDepartures)
          commit('updateCalendarEvents', { calendarGrouping: calendarGrouping, calendarID: calendarID, events: events })
          commit('calendarState', { calendarGrouping: calendarGrouping, opts: { refresh: false, error: false} })
          // console.log("Finished Transit Fetch", calendarID)
        })
        .catch(function(axiosError) {
          var error
          var errorData = {}
          var errorType = ""

          if (axiosError.isAxiosError) {
            error = axiosError.response
            errorData = error.data
            errorType = error.status
          }else{
            error = axiosError
          }

          AirisNotifier.error( "Calendar Retrieval Error", errorData, error);
          commit('calendarState', { calendarGrouping: calendarGrouping, opts: { refresh: false, error: true, errorMessage: errorData.message }})
        })
        .then(function(){
          resolve()
        });
      });

      }
    })
  }
}

// mutations
const mutations = {
  setupCalendar(state, calendar){
    let calendarGrouping = calendar.calendarGrouping
    let calendarIDs = calendar.calendarIDs
    let opts = {calendarIDs: calendarIDs, events: [] }
    if(state.calendars[calendarGrouping] === undefined || state.calendars[calendarGrouping] === null){
      // Vue.set(state.calendars, calendarGrouping, opts)
    }
  },
  updateCalendarEvents (state, calendar) {
    let calendarGrouping = calendar.calendarGrouping
    let calendarID = calendar.calendarID
    let allEvents = calendar.events
    var today = LuxonDateTime.local()
    var nextWeek = today.plus({days:7})
    allEvents.forEach((event, i) => {
      event.startLuxonDateTime = LuxonDateTime.fromISO(event.start.dateTime)
    });
    var tomorrow = today.plus({days: 1})

    var filteredEvents = _.filter(allEvents, (event)=>{
      return (event.startLuxonDateTime.hasSame(today, 'day') ||
              event.startLuxonDateTime.hasSame(tomorrow, 'day') || (
                debugEventWindow &&
                event.startLuxonDateTime < nextWeek
              ))
    })

    filteredEvents.forEach((event, i) => {
      event.endLuxonDateTime = LuxonDateTime.fromISO(event.end.dateTime)
      var hour = event.startLuxonDateTime.hour
      event.timeOfDay = {
        early: (hour < 9),
        morning: false,
        afternoon: false,
        evening: false
      }
      if(hour < 9){
        event.timeOfDay.early = true
      }else if (hour < 12) {
        event.timeOfDay.morning = true
      }else if (hour < 17) {
        event.timeOfDay.afternoon = true
      }else {
        event.timeOfDay.evening = true
      }

    });

    let opts = {calendarID: calendarID, events: filteredEvents }
    if(state.calendars[calendarGrouping] === undefined || state.calendars[calendarGrouping] === null){
      // Vue.set(state.calendars, calendarGrouping, opts)
    }else {
      state.calendars[calendarGrouping].events = filteredEvents
    }
  },
  calendarState (state, calendar) {
    let calendarGrouping = calendar.calendarGrouping
    let opts = calendar.opts
    if(state.calendarState[calendarGrouping] === undefined || state.calendarState[calendarGrouping] === null){
      // debugger
      // Vue.set(state.calendarState, calendarGrouping, { refresh: false, error: false, errorMessage: "" })
    }

    if(opts.refresh !== undefined && opts.refresh !== null){
      state.calendarState[calendarGrouping].refresh = opts.refresh
    }
    if(opts.error !== undefined && opts.error !== null){
      state.calendarState[calendarGrouping].error = opts.error
    }
    if(opts.errorMessage !== undefined && opts.errorMessage !== null){
      state.calendarState[calendarGrouping].errorMessage = opts.errorMessage
    }

  }
}

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
