import { createStore } from 'vuex'
import * as actions from './actions'
import transit from './modules/transit'
import faceDetector from './modules/face-detector'
import calendar from './modules/calendar'
import weather from './modules/weather'
import solar from './modules/solar'
import climate from './modules/climate'
import queryString from 'query-string'


const debug = process.env.NODE_ENV !== 'production'
var query = queryString.parse(window.location.search)

let windowHidden = false // Force a load for everything on first rendering

const store = new createStore({
  actions,
  state: {
    browser: {
      hidden: windowHidden,
    },
    app: {
      experiment: ("true" == query.experiment),
      debug: ("true" == query.debug),
    }
  },
  mutations: {
    documentVisibility (state) {
      if(state.app.debug && false){
        console.log("Document visibily change: ", document.visibilityState)
      }
      if (document.visibilityState === "visible") {
        state.browser.hidden = false
      } else  {
        state.browser.hidden = true
      }
    }
  },
  modules: {
    transit,
    faceDetector,
    calendar,
    weather,
    solar, 
    climate
  },
  strict: false,
})

let handleVisibilityChange = () => {
  store.commit('documentVisibility')
}

document.addEventListener("visibilitychange", handleVisibilityChange, false)

if (module.hot) {
  // accept actions and mutations as hot modules
  module.hot.accept(['./mutation-types', './modules/transit', './modules/face-detector', './modules/calendar', './modules/weather', './modules/solar', './modules/climate'], () => {
    // require the updated modules
    // have to add .default here due to babel 6 module output
    const newMutations = require('./mutation-types').default
    const newTransit = require('./modules/transit').default
    const newFaceDetector = require('./modules/face-detector').default
    const newCalendar = require('./modules/calendar').default
    const newWeather = require('./modules/weather').default
    const newSolar = require('./modules/solar').default
    const newClimate = require('./modules/climate').default
    // swap in the new actions and mutations
    store.hotUpdate({
      mutations: newMutations,
      modules: {
        transit: newTransit,
        faceDetector: newFaceDetector,
        calendar: newCalendar,
        weather: newWeather,
        solar: newSolar,
        climate: newClimate,
      }
    })
  })
}

export default store