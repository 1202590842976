<template>
  <div v-if="fetchingData" :solar="solar">

    <div v-if='solarError.active' class='row'>
      <div class='col-md-12'>
        <div class='mx-auto'> <div class='icon title error'><font-awesome-icon :icon="['fal', 'engine-warning']" /> </div>
        <h4 style='display: inline'>{{ solarError.message }}</h4></div>
      </div>
    </div>
    <div v-if='currentPowerFlow.populated'>
    <div class='row'>
      <div class='col-md-0' style="margin-right:8px; width: 5%">
        <h2 style="margin-top:16px"><font-awesome-icon :icon="['fal', 'bolt']" class='lead-icon'/></h2>
      </div>
      <div class='col-md-2'>
        <h4 :class='`pv-status-${currentPowerFlow.PV.status}`'><font-awesome-icon :icon="['fal', 'leaf']" class='solar-icon' />
             {{currentPowerFlow.PV.currentPower}} kW</h4>
        <h4><font-awesome-icon :icon="['fal', 'home']" class='solar-icon' />
        {{currentPowerFlow.LOAD.currentPower}} kW</h4>
      </div>
      <div class='col-md-0' style="width: 12%">
        
      </div>
      <div class='col-md-2 text-end offset-5'>
        <h4 :class='`pv-status-${currentPowerFlow.PV.status}`'>{{solarStats.production}} kWh</h4>
        <h4>{{solarStats.consumption}} kWh</h4>
      </div>
      <div class='col-md-0' style="width: 5%">
        <h2 style="margin-top:8px"><font-awesome-icon :icon="['fal', 'history']" flip="horizontal" class='lead-icon'/></h2>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import AirisNotifier from '../src/airis-notifier'
import { DateTime as LuxonDateTime, Duration as LuxonDuration } from 'luxon'
import { LinePath as VueniqueLinePath, Group as VueniqueGroup } from '@vueniquejs/vuenique'
import { useStore } from 'vuex'

export default {
  name: 'solar',
  data: () => {
  return {
    location: {
      id: '1910345',
      lat: 47.6708284,
      lon: -122.3853433,
    },
    currentSolarHistory: {
      populated: false,
    },
    solarStats: {
      production: 0,
      consumption: 0,
    },
    currentPowerFlow: {
      populated: false,
    },
    daytime: false,
    solarError: {
      active: false,
      message: '',
    },
    store: null
  }
  },
  computed: {
    solar() {
      let solarStore = this.$store.state.solar
      let solarID = solarStore.getSolarID(this.location)
      this.solarError.active = false

      let solarHistory = solarStore.solar[solarID].solarHistory
      let powerFlow = solarStore.solar[solarID].powerFlow
      let solarState = solarStore.storeState[solarID]

      if(solarState.lastRefresh !== undefined){
        this.solarError.active = solarState.error
        this.solarError.message = solarState.errorMessage
        if(solarState.error){
          return
        }

        if (solarHistory !== undefined){
          if(this.debug){
            console.log("Solar History Raw", solarHistory)
          }
          this.solarError.active = false
          this.currentSolarHistory = solarHistory
          var meters = this.currentSolarHistory.energyDetails.meters
          var consumptionSeries = _.find(meters, {'type': 'Consumption'})
          var productionSeries = _.find(meters, {'type': 'Production'})
          
          this.solarStats.consumption = _.round(_.sumBy(consumptionSeries.values, (o) => { return o.value}) / 1000.0, 2)
          this.solarStats.production = _.round(_.sumBy(productionSeries.values, (o) => { return o.value}) / 1000.0, 2)
          
          this.currentSolarHistory.populated = true
          if(this.debug){
            console.log("Current Solar History", this.currentSolarHistory)
          }
          

        }

        if(powerFlow !== undefined){
          if(this.debug){
            console.log("Power Flow", powerFlow)
          }
          this.solarError.active = false
          this.currentPowerFlow = powerFlow.siteCurrentPowerFlow

          this.currentPowerFlow.populated = true
          if(this.debug){
            console.log("Current Power Flow", this.currentPowerFlow)
          }
        }
      }
      return this.$store.state.solar.solar[solarID]
    }
  },
  methods: {
  fetchingData() {
    let solarID = this.$store.state.solar.getSolarID(this.location)
    return this.$store.state.solar.storeState[solarID]
  }
},
created() {
  this.store = useStore()   
  this.store.dispatch('solar/watchSolar', this.location)
  this.$nextTick(function () {

  })
},
setup () {
    
  },
}
</script>

<style lang="scss">
.solar-icon {
  display:inline-block;
  vertical-align:middle;
  margin-bottom:8px;
  margin-right:6px
}
.lead-icon {
  vertical-align: text-top;
  font-size:1.5em;
}

.pv-status-Active {
  color: #5EFF97;
}

.pv-status-Idle {
  opacity:0.6;
}
</style>
